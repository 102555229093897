import React from "react";
import { Link } from "react-router-dom";
import "./AppHeader.css";

const AppHeader = () => (
  <div className="AppHeader">
    <div className="AppHeader-container">
      <Link to="/" className="AppHeader-link AppHeader-center">
        <div className="AppHeader-title">ぷよたんβ</div>
        <div className="AppHeader-version">v0.3.0</div>
      </Link>
      <Link to="/" className="AppHeader-link AppHeader-backTop">
        部屋選択
      </Link>
      <Link to="/config" className="AppHeader-link AppHeader-config">
        設定
      </Link>
    </div>
  </div>
);

export default AppHeader;
