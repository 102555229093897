import React from "react";
import { RouteComponentProps } from "react-router-dom";
import DB from "../libs/DB";
import "./Replay.css";
import {
  Puyotan,
  PuyoPos,
  PuyoKind,
  IPuyotanAction,
  PuyoPair,
  PuyotanStatus,
  PuyotanActionType
} from "../libs/Puyotan";
import Base64Util from "../libs/Base64Util";

interface IState {
  gameId: string;
  seed: string | null;
  puyotan: Puyotan | null;
  playerActionHistoryMap: Map<number, IActionHistory>;
  frameLimit: number;
  isPlaying: boolean;
  chartLinkMap: Map<number, string>;
}

interface IActionHistory {
  actionMap: Map<number, IPuyotanAction>;
}

class Replay extends React.Component<
  RouteComponentProps<{ id: string }>,
  IState
> {
  constructor(props: RouteComponentProps<{ id: string }>) {
    super(props);
    this.state = {
      gameId: this.props.match.params.id,
      seed: null,
      puyotan: null,
      playerActionHistoryMap: new Map(),
      frameLimit: 0,
      isPlaying: false,
      chartLinkMap: new Map()
    };

    (window as any).debug = () => {
      console.log(this.state);
    };
    (window as any).state = () => {
      return this.state;
    };
  }

  componentDidMount = () => {
    DB.fetchGameDocument(this.state.gameId, docs => {
      this.setState({
        seed: docs.seed
      });
      let isFetchedFlags = [false, false];
      [0, 1].forEach(id => {
        DB.fetchGamePlayerDocument(this.state.gameId, id, doc => {
          if (doc != null && doc.actionMap != null) {
            const actionMap = new Map<number, IPuyotanAction>();
            Object.keys(doc.actionMap).forEach(key => {
              actionMap.set(Number(key), doc.actionMap[key]);
            });
            const history = {
              actionMap: actionMap
            };
            const historyMap = this.state.playerActionHistoryMap;
            historyMap.set(id, history);
            this.setState({
              playerActionHistoryMap: historyMap
            });
          }
          isFetchedFlags[id] = true;
          if (isFetchedFlags.every(v => v)) {
            this.onloadComplete();
          }
        });
      });
    });
  };

  onloadComplete() {
    console.log("onloadComplete");
    if (!this.state.seed) return;
    const chartLinkMap = new Map([
      [0, `https://sim.refpuyo.net#${this.state.seed}!`],
      [1, `https://sim.refpuyo.net#${this.state.seed}!`]
    ]);
    const puyotan = new Puyotan(Base64Util.base64stoNum(this.state.seed));
    puyotan.start();
    for (let i = 1; i < 10000; i++) {
      [0, 1].forEach(pid => {
        const history = this.state.playerActionHistoryMap.get(pid);
        if (history) {
          const action = history.actionMap.get(i);
          if (action) {
            puyotan.setAction(pid, action);
          }
        }
      });
      if (puyotan.canStepNextFrame()) {
        puyotan.stepNextFrame();
        [0, 1].forEach(pid => {
          const player = puyotan.players[pid];
          if (!player) return;
          const prevHistory = player.histories[puyotan.frame - 1];
          if (prevHistory !== null && prevHistory.remainingFrame == 0) {
            const action = prevHistory.action as any;
            if (action.type === PuyotanActionType.OJAMA) {
              let s = "";
              for (let y = 1; y <= 13; y++) {
                for (let x = 1; x <= 6; x++) {
                  let kind = player.field.get(new PuyoPos(x, y));
                  s += Base64Util.numToBase64c(25 + kind);
                }
              }
              for (let i = s.length - 1; i >= 0; i--) {
                if (s[i] !== "z") break;
                s = s.slice(0, -1);
              }
              let link = chartLinkMap.get(pid) || "";
              chartLinkMap.set(pid, link + s);
            }
            if (action.type === PuyotanActionType.PUT) {
              let link = chartLinkMap.get(pid) || "";
              const n = 4 * (action.x - 1) + action.dir + 1;
              chartLinkMap.set(pid, link + Base64Util.numToBase64c(n));
            }
          }
        });
      } else {
        break;
      }
    }
    this.setState({
      frameLimit: puyotan.frame,
      isPlaying: true,
      chartLinkMap: chartLinkMap
    });
    this.moveFrame(0);
    this.startPlayLoop();
  }

  componentWillUnmount() {}

  render(): JSX.Element {
    return (
      <div className="Replay">
        {this.ojamaToJSX(0)}
        {this.ojamaToJSX(1)}
        {this.fieldToJSX(0)}
        {this.fieldToJSX(1)}
        {this.frameToJSX()}
        {this.frameRangeToJSX()}
        {this.nextToJSX(0, 1)}
        {this.nextToJSX(0, 2)}
        {this.nextToJSX(1, 1)}
        {this.nextToJSX(1, 2)}
        {this.scoreToJSX(0)}
        {this.scoreToJSX(1)}
        {this.chainToJSX(0)}
        {this.chainToJSX(1)}
        {this.chartLinkToJSX(0)}
        {this.chartLinkToJSX(1)}
        {this.buttonToJSX(
          this.state.isPlaying ? "||" : "▶",
          "Replay-buttonStartStop",
          false,
          this.togglePlaying
        )}
      </div>
    );
  }

  togglePlaying = () => {
    if (!this.state.isPlaying) this.startPlayLoop();
    this.setState({
      isPlaying: !this.state.isPlaying
    });
  };

  startPlayLoopTimeoutId: NodeJS.Timeout | null = null;
  startPlayLoop = () => {
    if (this.startPlayLoopTimeoutId) return;
    const loop = () => {
      this.startPlayLoopTimeoutId = setTimeout(() => {
        if (!this.state.puyotan || !this.state.isPlaying) {
          this.startPlayLoopTimeoutId = null;
          return;
        }
        this.moveFrame(this.state.puyotan.frame + 1);
        if (this.state.puyotan.frame >= this.state.frameLimit) {
          this.startPlayLoopTimeoutId = null;
          this.setState({
            isPlaying: false
          });
        } else {
          loop();
        }
      }, 500);
    };
    loop();
  };

  fieldToJSX(id: number): JSX.Element {
    const puyotan = this.state.puyotan;
    const puyoElements = new Array<JSX.Element>();
    let isHoldAllClear = false;
    if (puyotan != null) {
      let player = puyotan.players[id];
      isHoldAllClear = player.isHoldAllClear;
      let field = player.field;
      for (let y = 1; y <= 13; y++) {
        for (let x = 1; x <= 6; x++) {
          const pos = new PuyoPos(x, y);
          const kind = field.get(pos);
          const puyoStyle = this.getFieldPuyoStyle(pos);
          puyoElements.push(
            <div
              key={`${x}_${y}`}
              className={`Replay-puyo ${this.puyoKindToClassName(kind)}`}
              style={puyoStyle}
            />
          );
        }
      }
    }
    return (
      <div className={`Replay-field Replay-field${id + 1}P`}>
        <div className="Replay-peke" />
        {puyoElements}
        {this.controlPairToJSX(id)}
        {isHoldAllClear ? <div className="Replay-isHoldAllClear" /> : null}
      </div>
    );
  }

  getFieldPuyoStyle(pos: PuyoPos) {
    return {
      left: (pos.x - 1) * 32,
      top: (13 - pos.y) * 32
    };
  }

  puyoKindToClassName(kind: PuyoKind) {
    const prefix = DB.getPuyoSkin() == "B" ? "B" : "";
    switch (kind) {
      case PuyoKind.RED:
        return "Replay-puyo Replay-puyoRed" + prefix;
      case PuyoKind.BLUE:
        return "Replay-puyo Replay-puyoBlue" + prefix;
      case PuyoKind.GREEN:
        return "Replay-puyo Replay-puyoGreen" + prefix;
      case PuyoKind.YELLOW:
        return "Replay-puyo Replay-puyoYellow" + prefix;
      case PuyoKind.OJAMA:
        return "Replay-puyo Replay-puyoOjama";
      default:
        return "Replay-puyo Replay-puyoBlank";
    }
  }

  frameToJSX(): JSX.Element {
    let frame = 0;
    if (this.state.puyotan) {
      frame = this.state.puyotan.frame;
    }
    return (
      <div className="Replay-frameArea">
        <div className="Replay-frameText">フレーム</div>
        <div className="Replay-frameNumber">{frame}</div>
      </div>
    );
  }

  frameRangeToJSX(): JSX.Element {
    let value = 0;
    if (this.state.puyotan) {
      value = this.state.puyotan.frame;
    }
    return (
      <div className="Replay-frameRange">
        <input
          type="range"
          min="0"
          max={this.state.frameLimit}
          step="1"
          value={value}
          onChange={e => {
            let frame = Number(e.target.value);
            this.moveFrame(frame);
          }}
        />
      </div>
    );
  }

  nextToJSX(id: number, nextDiff: number): JSX.Element {
    let pair = new PuyoPair();
    if (this.state.puyotan) {
      if (this.state.puyotan.frame > 0) {
        pair = this.state.puyotan.next.getPuyoPair(
          this.state.puyotan.players[id].nextPos + nextDiff
        );
      } else {
        pair = this.state.puyotan.next.getPuyoPair(nextDiff - 1);
      }
    }
    return (
      <div className={`Replay-next Replay-next${nextDiff}${id + 1}P`}>
        <div className={`${this.puyoKindToClassName(pair.sub)}`} />
        <div
          className={`${this.puyoKindToClassName(pair.axis)} Replay-nextAxis`}
        />
      </div>
    );
  }

  controlPairToJSX(id: number): JSX.Element | null {
    // null  操作位置
    // ojama 非表示
    // put   設置位置
    // chain 非表示
    // fall  非表示
    // PLAY中以外 → 全て非表示
    const puyotan = this.state.puyotan;
    if (!puyotan) return null;
    if (puyotan.status !== PuyotanStatus.PLAY) return null;
    let operation = { x: 3, dir: 0 };
    const pair = puyotan.next.getPuyoPair(puyotan.players[id].nextPos);
    const currentHistory = puyotan.players[id].histories[puyotan.frame];
    if (currentHistory) {
      const currentAction = currentHistory.action as any;
      if (currentAction.type !== PuyotanActionType.PUT) return null;
      operation = { x: currentAction.x, dir: currentAction.dir };
    }
    let axisPos = new PuyoPos(operation.x, 15);
    let subPos = this.getSubPos(axisPos, operation.dir);
    return (
      <div className="Replay-puyo">
        <div
          className={`${this.puyoKindToClassName(pair.sub)}`}
          style={this.getFieldPuyoStyle(subPos)}
        />
        <div
          className={`${this.puyoKindToClassName(pair.axis)}`}
          style={this.getFieldPuyoStyle(axisPos)}
        />
      </div>
    );
  }

  getSubPos(axisPos: PuyoPos, dir: number) {
    switch (dir) {
      case 0:
        return new PuyoPos(axisPos.x, axisPos.y + 1);
      case 1:
        return new PuyoPos(axisPos.x + 1, axisPos.y);
      case 2:
        return new PuyoPos(axisPos.x, axisPos.y - 1);
      case 3:
        return new PuyoPos(axisPos.x - 1, axisPos.y);
      default:
        throw Error(`unsupported dir ${dir}`);
    }
  }

  ojamaToJSX(id: number): JSX.Element | null {
    if (!this.state.puyotan) return null;
    const player = this.state.puyotan.players[id];
    let ojamaNum = player.activeOjama + player.nonActiveOjama;
    const ojama7Num = Math.floor(ojamaNum / 1440);
    ojamaNum -= ojama7Num * 1440;
    const ojama6Num = Math.floor(ojamaNum / 720);
    ojamaNum -= ojama6Num * 720;
    const ojama5Num = Math.floor(ojamaNum / 360);
    ojamaNum -= ojama5Num * 360;
    const ojama4Num = Math.floor(ojamaNum / 180);
    ojamaNum -= ojama4Num * 180;
    const ojama3Num = Math.floor(ojamaNum / 30);
    ojamaNum -= ojama3Num * 30;
    const ojama2Num = Math.floor(ojamaNum / 6);
    ojamaNum -= ojama2Num * 6;
    const ojama1Num = ojamaNum;
    let ojamaList = [];
    for (let i = 0; i < ojama7Num; i++) ojamaList.push(7);
    for (let i = 0; i < ojama6Num; i++) ojamaList.push(6);
    for (let i = 0; i < ojama5Num; i++) ojamaList.push(5);
    for (let i = 0; i < ojama4Num; i++) ojamaList.push(4);
    for (let i = 0; i < ojama3Num; i++) ojamaList.push(3);
    for (let i = 0; i < ojama2Num; i++) ojamaList.push(2);
    for (let i = 0; i < ojama1Num; i++) ojamaList.push(1);
    let jsxList = [];
    for (let i = 0; i < Math.min(ojamaList.length, 6); i++) {
      jsxList.push(
        <div
          key={`${i}`}
          className={`Replay-ojama Replay-ojama${ojamaList[i]}`}
          style={{
            left: 32 * i
          }}
        />
      );
    }
    return (
      <div className={`Replay-ojamaArea Replay-ojamaArea${id + 1}P`}>
        {jsxList}
      </div>
    );
  }

  scoreToJSX(id: number): JSX.Element {
    let score = 0;
    if (this.state.puyotan) score = this.state.puyotan.players[id].score;
    return (
      <div className={`Replay-score Replay-score${id + 1}P`}>
        <div className="Replay-scoreLeft">Score</div>
        <div className="Replay-scoreRight">{score}</div>
      </div>
    );
  }

  chainToJSX(id: number): JSX.Element {
    let chain = 0;
    if (this.state.puyotan) chain = this.state.puyotan.players[id].chain;
    return (
      <div className={`Replay-chain Replay-chain${id + 1}P`}>{chain} 連鎖</div>
    );
  }

  chartLinkToJSX(id: number): JSX.Element | null {
    let chartLink = this.state.chartLinkMap.get(id);
    if (!chartLink) return null;
    return (
      <a
        className={`Replay-chartLink Replay-chartLink${id + 1}P`}
        href={chartLink}
        target="_blank"
      >
        {`ぷよ譜を開く(${id + 1}P)`}
      </a>
    );
  }

  buttonToJSX(
    value: string,
    className: string,
    disabled: boolean,
    onClick: () => void
  ): JSX.Element {
    const onStart = (e: any) => {
      if (disabled) return;
      const tapEventType =
        window.ontouchstart === null ? "touchstart" : "mousedown";
      if (e.type === tapEventType) {
        onClick();
      }
    };
    return (
      <div
        onTouchStart={onStart}
        onMouseDown={onStart}
        className={`Replay-button ${className} ${
          disabled ? `Replay-button-disabled` : ""
        }`}
      >
        {value}
      </div>
    );
  }

  moveFrame(frame: number) {
    if (!this.state.seed) return;
    console.log("frame:", frame);
    const puyotan = new Puyotan(Base64Util.base64stoNum(this.state.seed));
    if (frame > 0) {
      puyotan.start();
      for (let i = 1; i < frame; i++) {
        [0, 1].forEach(pid => {
          const history = this.state.playerActionHistoryMap.get(pid);
          if (history) {
            const action = history.actionMap.get(i);
            if (action) {
              puyotan.setAction(pid, action);
            }
          }
        });
        puyotan.stepNextFrame();
      }
    }
    this.setState({ puyotan: puyotan });
  }
}

export default Replay;
