import React from "react";
import ReactDOM from "react-dom";
import { RouteComponentProps } from "react-router-dom";
import Game from "./Game";
import DB from "../libs/DB";
import "./Room.css";

interface IState {
  roomId: string;
  chatText: string;
  chats: IChat[];
}
interface IChat {
  createdAt: Date;
  name: string;
  text: string;
  color: string;
  gameId: string | undefined;
}

class Room extends React.Component<
  RouteComponentProps<{ id: string }>,
  IState
> {
  cancelChatListener: Function | null = null;
  onAuthChangedListener: Function | null = null;

  constructor(props: RouteComponentProps<{ id: string }>) {
    super(props);
    this.state = {
      roomId: this.props.match.params.id,
      chatText: "",
      chats: []
    };
  }

  componentDidMount = () => {
    this.cancelChatListener = DB.observeRoomChatCollection(
      this.state.roomId,
      docs => {
        this.setState({
          chats: [
            ...docs.map(v => ({
              createdAt:
                v.createdAt != undefined ? v.createdAt.toDate() : new Date(),
              name: v.name,
              text: v.text,
              color: v.color,
              gameId: v.gameId
            })),
            ...this.state.chats
          ]
        });
      }
    );
    document.addEventListener("keydown", e => {
      const t = e.target as any;
      if (t == null) return;
      if (
        t.nodeName == "INPUT" &&
        t.className == "Room-chatInput" &&
        e.keyCode == 13
      ) {
        this.sendChat();
        e.preventDefault();
      }
    });
    this.onAuthChangedListener = DB.getFirebase()
      .auth()
      .onAuthStateChanged(user => {
        if (user) {
          DB.moveRoom(this.state.roomId);
        }
      });
  };

  componentWillUnmount() {
    if (this.cancelChatListener) this.cancelChatListener();
    if (this.onAuthChangedListener) this.onAuthChangedListener();
    DB.moveRoom(null);
  }

  render(): JSX.Element {
    const roomId = this.props.match.params.id;
    return (
      <div className="Room">
        {/* <h1 className="Room-headline">Room: {roomId}</h1> */}
        <Game roomId={roomId} />
        <div className="Room-bottomArea">
          <div className="Room-infoArea">
            {"<簡単なルール説明>"}
            <br />
            {"基本ぷよ通と同じルールですが 1P, 2P は同時に着手します。"}
            <br />
            {"行動には以下のフレーム数が必要です。"}
            <br />
            {"・設置：２フレーム"}
            <br />
            {"・連鎖：３フレーム ※ただしクイックは２フレーム"}
            <br />
            {"・おじゃま落下：１フレーム"}
            <br />
            {"割り込み有り。不正回し・ツモ捨て可能。"}
            <br />
            {"※ 仕様は細かく変更される可能性があります"}
          </div>
          {this.chatsToJSX()}
        </div>
      </div>
    );
  }

  chatsToJSX(): JSX.Element {
    const chatElms = this.state.chats.map((v, i) => {
      const h = v.createdAt.getHours();
      const m = v.createdAt.getMinutes();
      const timeStr = `${("00" + h).substr(-2)}:${("00" + m).substr(-2)}`;
      return (
        <div key={i} className="Room-chatContent">
          <div className="Room-chatContentName">{v.name}</div>
          <div className="Room-chatContentText" style={{ color: v.color }}>
            <span className="Room-chatContentTime">{timeStr}</span>
            {v.text}
            {v.gameId ? <a href={`/replays/${v.gameId}`}>[リプレイ]</a> : null}
          </div>
        </div>
      );
    });
    return (
      <div className={`Room-chatArea`}>
        <div className="Room-chatInputArea">
          <div className="Room-chatTitle">ルームチャット</div>
          <input
            id="Room-chatInput"
            className="Room-chatInput"
            type="text"
            value={this.state.chatText}
            placeholder="最大32文字まで"
            maxLength={32}
            onChange={e => {
              this.setState({ chatText: e.target.value });
            }}
          />
          <input
            type="button"
            value="送信"
            onClick={e => {
              this.sendChat();
            }}
          />
        </div>
        <div className="Room-chatContentsArea">{chatElms}</div>
      </div>
    );
  }

  sendChat() {
    DB.sendChat(this.state.roomId, this.state.chatText, "#000000");
    this.setState({
      chatText: ""
    });
  }
}

export default Room;
