/** ランダムな整数を生成するクラス */
class Random {
  private y: number;

  /** seed は正の整数(uint32) */
  constructor(seed: number = Math.random() * 2147483647) {
    this.y = seed;
  }

  /** uint32 の範囲でランダムな値を返す */
  next() {
    // xorshift で計算
    // 3回目の xor は多分 5 の typo だが、ぷよシミュWeb と乱数生成を合わせる為揃えている
    // (過去ログ見ると wikipedia の昔の記述参照したっぽい…)
    this.y = this.y ^ (this.y << 13);
    this.y = this.y ^ (this.y >> 17);
    this.y = this.y ^ (this.y << 15);
    return this.y >>> 0;
  }

  /** 0 ~ max未満のランダムな整数を返す */
  nextInt(max: number) {
    const r = Math.abs(this.next());
    return r % max;
  }
}

/** ぷよの種類用列挙型 */
export enum PuyoKind {
  BRANK = 0,
  RED,
  GREEN,
  BLUE,
  YELLOW,
  PURPLE,
  IRON,
  OJAMA,
  WALL,
  PEKE
}

/** ぷよの組 */
export class PuyoPair {
  /** axis: 軸ぷよ, sub: 従属ぷよ */
  constructor(public axis = PuyoKind.BRANK, public sub = PuyoKind.BRANK) {}
}

/** ぷよNextテーブル */
export class PuyoNext {
  private table: PuyoPair[];

  /** period: 周期, random: 乱数ジェネレータ */
  constructor(period = 128, private random = new Random()) {
    this.table = new Array(period);
    for (let i = 0; i < period; i++) {
      this.table[i] = new PuyoPair(this.getRandomKind(), this.getRandomKind());
    }
  }

  /** num 番目のツモを取得する */
  getPuyoPair(num: number) {
    return this.table[num % this.table.length];
  }

  private getRandomKind() {
    switch (this.random.nextInt(4)) {
      case 0:
        return PuyoKind.RED;
      case 1:
        return PuyoKind.GREEN;
      case 2:
        return PuyoKind.BLUE;
      case 3:
        return PuyoKind.YELLOW;
      default:
        throw new Error("unexpected error");
    }
  }
}

/** ぷよの位置 */
export class PuyoPos {
  /** x: x座標(1-6), y: y座標(1-14) */
  constructor(public x = 0, public y = 0) {}

  /** フィールド範囲内に収まっているか */
  isValid() {
    return 1 <= this.x && this.x <= X && 1 <= this.y && this.y <= Y;
  }
}

/** utilities */
const X = 6; // フィールド幅
const Y = 14; // フィールド高さ
const fieldPoses = new Array<PuyoPos>(); // フィールド総なめ用座標リスト
for (let y = 1; y <= Y; y++) {
  for (let x = 1; x <= X; x++) {
    fieldPoses.push(new PuyoPos(x, y));
  }
}

/**
 * ぷよフィールド
 * 14段目も存在するが、fall()後に消去される（通のように残ったりしない）
 */
export class PuyoField {
  private puyoKinds: PuyoKind[];

  constructor() {
    this.puyoKinds = [];
    for (let i = 0; i < X * Y; i++) {
      this.puyoKinds.push(PuyoKind.BRANK);
    }
  }

  get(pos: PuyoPos): PuyoKind {
    if (!pos.isValid()) return PuyoKind.WALL;
    const p = X * (pos.y - 1) + (pos.x - 1);
    return this.puyoKinds[p];
  }

  set(pos: PuyoPos, kind: PuyoKind): void {
    if (!pos.isValid()) return console.error("out of range!", pos, kind);
    const p = X * (pos.y - 1) + (pos.x - 1);
    this.puyoKinds[p] = kind;
  }

  getHeight(x: number): number {
    for (let y = 13; y >= 1; y--) {
      if (this.get(new PuyoPos(x, y)) !== PuyoKind.BRANK) return y;
    }
    return 0;
  }

  /** フィールドのぷよを設置し、落下が発生したかどうかを返す */
  fall(): Boolean {
    let isFall = false; // 落下発生フラグ
    for (let x = 1; x <= X; x++) {
      let t = 1;
      for (let y = 1; y <= Y; y++) {
        let p = this.get(new PuyoPos(x, y));
        if (p === PuyoKind.BRANK) continue;
        this.set(new PuyoPos(x, y), this.get(new PuyoPos(x, t)));
        this.set(new PuyoPos(x, t), p);
        if (t !== y) isFall = true;
        t++;
      }
    }
    for (let x = 1; x <= X; x++) this.set(new PuyoPos(x, 14), PuyoKind.BRANK);
    return isFall;
  }

  canFall(): Boolean {
    for (let x = 1; x <= X; x++) {
      for (let y = 2; y <= Y; y++) {
        const u = this.get(new PuyoPos(x, y));
        const d = this.get(new PuyoPos(x, y - 1));
        if (u !== PuyoKind.BRANK && d === PuyoKind.BRANK) return true;
      }
    }
    return false;
  }

  /** 1つぷよを設置し、設置した高さを返す */
  setAndFall(x: number, kind: PuyoKind): number {
    const h = this.getHeight(x);
    if (h >= 13) return 14;
    this.set(new PuyoPos(x, h + 1), kind);
    return h + 1;
  }

  private _countConnection(
    pos: PuyoPos,
    kind: PuyoKind,
    flagField: PuyoField
  ): number {
    if (this.get(pos) === PuyoKind.IRON) return 0;
    if (this.get(pos) === PuyoKind.PEKE) return 0;
    if (this.get(pos) === PuyoKind.WALL) return 0;
    if (this.get(pos) === PuyoKind.BRANK) return 0;
    if (this.get(pos) === PuyoKind.OJAMA) return 0;
    if (this.get(pos) !== kind) return 0;
    if (flagField.get(pos) !== PuyoKind.BRANK) return 0;
    if (pos.y >= 13) return 0;
    flagField.set(pos, kind);
    return (
      this._countConnection(new PuyoPos(pos.x, pos.y + 1), kind, flagField) +
      this._countConnection(new PuyoPos(pos.x + 1, pos.y), kind, flagField) +
      this._countConnection(new PuyoPos(pos.x, pos.y - 1), kind, flagField) +
      this._countConnection(new PuyoPos(pos.x - 1, pos.y), kind, flagField) +
      1
    );
  }

  /** pos と連結した数を返す (pos 自身も含む) */
  countConnection(pos: PuyoPos) {
    return this._countConnection(pos, this.get(pos), new PuyoField());
  }

  private _deleteConnection(pos: PuyoPos, kind: PuyoKind): number {
    if (this.get(pos) === PuyoKind.BRANK) return 0;
    if (this.get(pos) === PuyoKind.WALL) return 0;
    if (this.get(pos) === PuyoKind.OJAMA) {
      this.set(pos, PuyoKind.BRANK);
      return 0;
    }
    if (this.get(pos) !== kind) return 0;
    if (pos.y >= 13) return 0;
    this.set(pos, PuyoKind.BRANK);
    return (
      this._deleteConnection(new PuyoPos(pos.x, pos.y + 1), kind) +
      this._deleteConnection(new PuyoPos(pos.x + 1, pos.y), kind) +
      this._deleteConnection(new PuyoPos(pos.x, pos.y - 1), kind) +
      this._deleteConnection(new PuyoPos(pos.x - 1, pos.y), kind) +
      1
    );
  }

  /** pos と連結したぷよを消して、消えた個数を返す */
  deleteConnection(pos: PuyoPos): number {
    return this._deleteConnection(pos, this.get(pos));
  }

  canFire(): Boolean {
    let flags = new PuyoField();
    for (let y = 1; y < Y; y++) {
      for (let x = 1; x <= X; x++) {
        let pos = new PuyoPos(x, y);
        if (this._countConnection(pos, this.get(pos), flags) >= 4) return true;
      }
    }
    return false;
  }

  stepFire() {
    class Step {
      num = 0; // 消えたぷよの数
      connections = new Array<number>(); // 連結数
      color = 0; // 色数
    }
    let step = new Step();
    let flags = new PuyoField();
    var colorSet = new Set();
    for (let y = 1; y < Y; y++) {
      for (let x = 1; x <= X; x++) {
        let pos = new PuyoPos(x, y);
        let kind = this.get(pos);
        let n = this._countConnection(pos, kind, flags);
        if (n >= 4) {
          let nnnnnh = this.deleteConnection(pos);
          step.num += n;
          step.connections.push(n);
          colorSet.add(kind);
        }
      }
    }
    step.color = colorSet.size;
    return step;
  }

  copy() {
    let fd = new PuyoField();
    fieldPoses.forEach(pos => {
      fd.set(pos, this.get(pos));
    });
    return fd;
  }

  equal(field: PuyoField) {
    for (let i in fieldPoses) {
      let pos = fieldPoses[i];
      if (this.get(pos) !== field.get(pos)) return false;
    }
    return true;
  }

  isAllClear() {
    for (let y = 1; y < Y; y++) {
      for (let x = 1; x <= X; x++) {
        if (this.get(new PuyoPos(x, y)) !== PuyoKind.BRANK) return false;
      }
    }
    return true;
  }

  map(transform: Function) {
    let array = [];
    for (let y = 1; y <= Y; y++) {
      for (let x = 1; x <= X; x++) {
        let pos = new PuyoPos(x, y);
        let kind = this.get(pos);
        array.push(transform(pos, kind));
      }
    }
    return array;
  }
}

/** Action タイプ */
export enum PuyotanActionType {
  NONE = -1,
  PASS = 0,
  PUT,
  CHAIN,
  CHAIN_FALL,
  OJAMA
}

/** Action インタフェース */
export interface IPuyotanAction {
  type: PuyotanActionType;
}

export class PuyotanActionNone implements IPuyotanAction {
  type = PuyotanActionType.NONE;
}

export class PuyotanActionPass implements IPuyotanAction {
  type = PuyotanActionType.PASS;
}

export class PuyotanActionPut implements IPuyotanAction {
  type = PuyotanActionType.PUT;
  constructor(public x: number, public dir: number) {}
}

export class PuyotanActionChain implements IPuyotanAction {
  type = PuyotanActionType.CHAIN;
}

export class PuyotanActionChainFall implements IPuyotanAction {
  type = PuyotanActionType.CHAIN_FALL;
}

export class PuyotanActionOjama implements IPuyotanAction {
  type = PuyotanActionType.OJAMA;
}

export class PuyotanPlayerHistory {
  constructor(public action: IPuyotanAction, public remainingFrame = 0) {}
}

export class PuyotanPlayer {
  field = new PuyoField();
  histories = new Array<PuyotanPlayerHistory>();
  nextPos = 0;
  score = 0;
  usedScore = 0;
  nonActiveOjama = 0;
  activeOjama = 0;
  sendOjama = 0;
  chain = 0;
  isAlive = true;
  isHoldAllClear = false;
}

/** Action タイプ */
export enum PuyotanStatus {
  WAIT = 0,
  PLAY,
  FINISH
}

/**
 * ぷよたんを管理する
 * @example
 * let puyotan = new Puyotan(1234); // set seed
 * puyotan.start();
 * puyotan.setAction(0, new PuyotanActionPut(2, 3));
 * puyotan.setAction(1, new PuyotanActionPut(2, 3));
 * puyotan.stepNextFrame();
 */
export class Puyotan {
  next: PuyoNext;
  players: PuyotanPlayer[];
  frame: number = 0;
  status: PuyotanStatus = PuyotanStatus.WAIT;
  winnerId: number | null = null;
  seed: number;
  random: Random;

  constructor(seed: number) {
    this.seed = seed;
    this.random = new Random(seed);
    this.next = new PuyoNext(1000, this.random); // ぷよシミュWebに合わせている
    this.players = [new PuyotanPlayer(), new PuyotanPlayer()]; // 3人以上にも対応可能
  }

  start() {
    if (this.status === PuyotanStatus.WAIT) {
      this.frame = 1;
      this.status = PuyotanStatus.PLAY;
    }
  }

  /**
   * Player の行動を決定し、変更があったかを返す
   * PASS or PUT が選択可能
   * PUT は硬直 1
   */
  setAction(id: number, action: IPuyotanAction) {
    if (this.status !== PuyotanStatus.PLAY) return false;
    if (this.players[id].histories[this.frame] != null) return false;
    let history: PuyotanPlayerHistory;
    switch (action.type) {
      case PuyotanActionType.PASS:
        history = new PuyotanPlayerHistory(action);
        break;
      case PuyotanActionType.PUT:
        history = new PuyotanPlayerHistory(action, 1);
        break;
      case PuyotanActionType.NONE:
        history = new PuyotanPlayerHistory(action);
        break;
      default:
        throw new Error("unsupported action type.");
    }
    this.players[id].histories[this.frame] = history;
    return true;
  }

  canStepNextFrame() {
    if (this.frame <= 0) return false;
    return this.players.every(p => p.histories[this.frame] != null);
  }

  /** 全プレイヤーが行動決定していた場合、フレームを処理し次フレームに遷移する */
  stepNextFrame(): void {
    // 0. 行動選択
    // パス・設置から選択（※硬直中は選択は無効化される）（全プレイヤーが選択するまで待つ）
    if (!this.canStepNextFrame())
      return console.warn("can not step next frame.");

    // 1. 予約
    // 硬直有りの行動なら次フレームを予約する
    this.players.forEach((p, id) => {
      let history = p.histories[this.frame];
      if (history.remainingFrame > 0) {
        p.histories[this.frame + 1] = new PuyotanPlayerHistory(
          history.action,
          history.remainingFrame - 1
        );
      }
    });

    // 2. 行動実行
    // 現在フレームが硬直カウント０であれば行動を実行
    // PASS: なにもしない
    // PUT: ぷよを設置し、もし消せるぷよがあれば CHAIN を行動予約
    // CHAIN: ぷよを消去し、もし落下するぷよがあれば CHAIN_FALL を行動予約
    // CHAIN_FALL: ぷよを落下し、もし消せるぷよがあれば CHAIN を行動予約、なければ送ったおじゃまをアクティブ化する
    // OJAMA: アクティブなおじゃまから最大５段分設置し、もし残りがあれば OJAMA を行動予約
    this.players.forEach((p, id) => {
      const history = p.histories[this.frame];
      if (history.remainingFrame !== 0) return;
      switch (history.action.type) {
        case PuyotanActionType.PASS:
          return;
        case PuyotanActionType.PUT:
          const nextPair = this.next.getPuyoPair(p.nextPos);
          this.doPutAction(id, history.action as PuyotanActionPut, nextPair);
          if (p.field.canFire()) {
            p.chain = 0;
            p.sendOjama = 0;
            const history = new PuyotanPlayerHistory(
              new PuyotanActionChain(),
              1
            );
            p.histories[this.frame + 1] = history;
          }
          return;
        case PuyotanActionType.CHAIN:
          p.isHoldAllClear = false;
          p.chain++;
          const info = p.field.stepFire();
          p.score += this.calcScore(
            info.num,
            info.color,
            info.connections,
            p.chain
          );
          let ojama = Math.floor((p.score - p.usedScore) / 70); // 発生したおじゃま
          p.usedScore += ojama * 70;
          if (p.nonActiveOjama > 0) {
            // 自分の非アクティブおじゃまがあれば先に対応（割子）
            let usedNum = Math.min(ojama, p.nonActiveOjama);
            p.nonActiveOjama -= usedNum;
            ojama -= usedNum;
          }
          if (p.activeOjama > 0) {
            // 自分のアクティブおじゃまがあれば後に対応
            let usedNum = Math.min(ojama, p.activeOjama);
            p.activeOjama -= usedNum;
            ojama -= usedNum;
          }
          if (ojama > 0) {
            // 対応後に残ったおじゃまは全員に送信
            this.sendOjama(id, ojama);
            p.sendOjama += ojama;
          }
          if (p.field.isAllClear()) {
            // 全消し発生時は岩１個分ボーナス付与
            p.score += 70 * 30;
            p.isHoldAllClear = true;
          }
          if (p.field.canFall()) {
            // 落下発生時は次フレーム予約
            p.histories[this.frame + 1] = new PuyotanPlayerHistory(
              new PuyotanActionChainFall()
            );
          } else {
            // 連鎖終了時は自分の送ったおじゃまをアクティブ化（クイック）
            this.activeOjama(id, p.sendOjama);
          }
          return;
        case PuyotanActionType.CHAIN_FALL:
          if (!p.field.canFall()) throw new Error("failed to fall puyo.");
          p.field.fall();
          if (p.field.canFire()) {
            // 連鎖継続時は次フレーム予約
            p.histories[this.frame + 1] = new PuyotanPlayerHistory(
              new PuyotanActionChain(),
              1
            );
          } else {
            // 連鎖終了時は自分の送ったおじゃまをアクティブ化
            this.activeOjama(id, p.sendOjama);
          }
          return;
        case PuyotanActionType.OJAMA:
          if (p.activeOjama <= 0) throw new Error("failed to fall ojama.");
          let fallOjamaNum = Math.min(p.activeOjama, 30);
          p.activeOjama -= fallOjamaNum;
          this.fallOjama(id, fallOjamaNum);
          return;
        case PuyotanActionType.NONE:
          return;
        default:
          throw new Error("unsupported action type.");
      }
    });

    // 3. 窒息判定
    // 生存プレイヤーが1人であれば勝敗を決定し終了
    // 全滅の場合引き分け
    let aliveCount = 0;
    let alivePlayerId: number | null = null;
    this.players.forEach((p, id) => {
      if (
        p.histories[this.frame + 1] == null &&
        p.field.get(new PuyoPos(3, 12)) !== PuyoKind.BRANK
      ) {
        p.isAlive = false;
      } else {
        aliveCount++;
        alivePlayerId = id;
      }
    });
    if (aliveCount < 2) {
      this.status = PuyotanStatus.FINISH;
      this.winnerId = alivePlayerId; // 引き分け時は null
    }

    // 4. おじゃま処理
    // 次フレームが操作可能でアクティブなおじゃまがあったらおじゃまを行動予約
    // ただし今フレームが OJAMA だった場合予約しない（よってカウンター可能）
    this.players.forEach((p, id) => {
      if (
        p.histories[this.frame].action.type !== PuyotanActionType.OJAMA &&
        p.histories[this.frame + 1] == null &&
        p.activeOjama > 0
      ) {
        p.histories[this.frame + 1] = new PuyotanPlayerHistory(
          new PuyotanActionOjama()
        );
      }
    });

    // 5. フレーム遷移
    // 次回行動可能で今フレームがパス以外ならツモを進める
    this.players.forEach((p, id) => {
      const currentHistory = p.histories[this.frame];
      const nextHistory = p.histories[this.frame + 1];
      if (
        currentHistory.action.type !== PuyotanActionType.PASS &&
        nextHistory == null
      ) {
        p.nextPos++;
      }
    });
    this.frame++;
  }

  private doPutAction(id: number, action: PuyotanActionPut, pair: PuyoPair) {
    const field = this.players[id].field;
    const x = action.x;
    let h: number;
    switch (action.dir) {
      case 0:
        h = field.setAndFall(x, pair.axis);
        field.setAndFall(x, pair.sub);
        break;
      case 1:
        const h1 = field.setAndFall(x, pair.axis);
        const h2 = field.setAndFall(x + 1, pair.sub);
        h = Math.max(h1, h2);
        break;
      case 2:
        h = field.setAndFall(x, pair.sub);
        field.setAndFall(x, pair.axis);
        break;
      case 3:
        const h3 = field.setAndFall(x, pair.axis);
        const h4 = field.setAndFall(x - 1, pair.sub);
        h = Math.max(h3, h4);
        break;
      default:
        throw Error(`unsupported direction range or type. ${action.dir}`);
    }
    this.players[id].score += 14 - h;
  }

  calcScore(num: number, color: number, connections: number[], chain: number) {
    const A = [
      0,
      8,
      16,
      32,
      64,
      96,
      128,
      160,
      192,
      224,
      256,
      288,
      320,
      352,
      384,
      416,
      448,
      480,
      512
    ];
    const B = [0, 3, 6, 12, 24];
    const C = [0, 2, 3, 4, 5, 6, 7, 10];
    let base = num * 10;
    let rate = A[chain - 1];
    if (rate === undefined) rate = 512;
    rate += B[color - 1];
    for (let i = 0; i < connections.length; i++) {
      let n = connections[i];
      if (n > 11) rate += 10;
      else rate += C[n - 4];
    }
    if (rate === 0) rate = 1;
    return base * rate;
  }

  sendOjama(senderId: number, ojama: number) {
    this.players.forEach((p, id) => {
      if (id !== senderId) {
        p.nonActiveOjama += ojama;
      }
    });
  }

  activeOjama(senderId: number, ojama: number) {
    this.players.forEach((p, id) => {
      if (id !== senderId) {
        const incOjama = Math.min(ojama, p.nonActiveOjama);
        p.activeOjama += incOjama;
        p.nonActiveOjama -= incOjama;
      }
    });
  }

  fallOjama(id: number, num: number) {
    while (num > 0) {
      if (num >= 6) {
        for (let x = 1; x <= 6; x++)
          this.players[id].field.setAndFall(x, PuyoKind.OJAMA);
        num -= 6;
      } else {
        let memo = [false, false, false, false, false, false];
        for (let i = 0; i < num; i++) {
          let pos = this.random.nextInt(6 - i);
          let cnt = 0;
          for (let j = 0; j < 6; j++) {
            if (!memo[j]) {
              if (cnt++ === pos) memo[j] = true;
            }
          }
        }
        for (let x = 1; x <= 6; x++) {
          if (memo[x - 1]) this.players[id].field.setAndFall(x, PuyoKind.OJAMA);
        }
        num = 0;
      }
    }
  }
}
