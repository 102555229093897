import React from "react";
import ReactDOM from "react-dom";
import { RouteComponentProps } from "react-router-dom";
import Game from "./Game";
import DB from "../libs/DB";
import "./Config.css";

interface IState {
  name: string;
  puyoSkin: string;
  seRange: string;
}

class Config extends React.Component<RouteComponentProps, IState> {
  onAuthChangedListener: Function | null = null;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      name: "",
      puyoSkin: DB.getPuyoSkin(),
      seRange: DB.getSeRange()
    };
  }

  componentDidMount = () => {
    DB.getFirebase();
    const user = DB.getUser();
    if (user != null) {
      this.setState({
        name: user.displayName || ""
      });
    }
    this.onAuthChangedListener = DB.getFirebase()
      .auth()
      .onAuthStateChanged(user => {
        if (user) {
          this.setState({
            name: user.displayName || ""
          });
        }
      });
  };

  componentWillUnmount() {
    if (this.onAuthChangedListener) this.onAuthChangedListener();
  }

  render(): JSX.Element {
    return (
      <div className="Config">
        <h1 className="Config-title">ユーザ設定</h1>
        <div className="Config-name">プレイヤー名</div>
        <input
          className="Config-nameInput"
          type="text"
          value={this.state.name}
          maxLength={32}
          onChange={e => {
            this.setState({ name: e.target.value });
          }}
        />
        <input
          className="Config-nameButton"
          type="button"
          value="保存"
          onClick={e => {
            DB.setProfile(this.state.name);
          }}
        />
        <div className="Config-puyoSkin">ぷよスキン</div>
        <div className="Config-puyoSkinA">
          <label>タイプA</label>
          <input
            type="radio"
            name="puyoSkin"
            value="A"
            checked={this.state.puyoSkin === "A"}
            onChange={() => {
              DB.setPuyoSkin("A");
              this.setState({ puyoSkin: "A" });
            }}
          />
        </div>
        <div className="Config-puyoSkinB">
          <label>タイプB</label>
          <input
            type="radio"
            name="puyoSkin"
            value="B"
            checked={this.state.puyoSkin === "B"}
            onChange={() => {
              DB.setPuyoSkin("B");
              this.setState({ puyoSkin: "B" });
            }}
          />
        </div>
        <div className="Config-puyo Config-puyoRedA" />
        <div className="Config-puyo Config-puyoGreenA" />
        <div className="Config-puyo Config-puyoBlueA" />
        <div className="Config-puyo Config-puyoYellowA" />
        <div className="Config-puyo Config-puyoRedB" />
        <div className="Config-puyo Config-puyoGreenB" />
        <div className="Config-puyo Config-puyoBlueB" />
        <div className="Config-puyo Config-puyoYellowB" />
        <div className="Config-se">効果音(SE)</div>
        <div className="Config-seRange">
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            value={this.state.seRange}
            onChange={e => {
              DB.setSeRange(e.target.value);
              this.setState({ seRange: e.target.value });
            }}
          />
        </div>
        <div className="Config-seRangeValue">{this.state.seRange}%</div>
      </div>
    );
  }
}

export default Config;
