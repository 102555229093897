import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import "./Rooms.css";
import DB from "../libs/DB";

interface IState {
  users: any[];
}

class Rooms extends React.Component<RouteComponentProps, IState> {
  cancelObserveOnlineUsers: Function | null = null;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      users: []
    };
    this.cancelObserveOnlineUsers = DB.observeOnlineUsers(users => {
      console.log(users);
      this.setState({
        users: users
      });
    });
  }

  componentWillUnmount() {
    if (this.cancelObserveOnlineUsers) this.cancelObserveOnlineUsers();
  }

  render(): JSX.Element {
    return (
      <div className="Rooms">
        <h1 className="Rooms-headline">対戦部屋を選択してください。</h1>
        <div className="Rooms-tiles-wrapper">
          <div className="Rooms-tile">
            <div className="Rooms-tileUserNum">{this.getRoomNum("a")}</div>
            <Link to="/rooms/a" className="Rooms-tileLink">
              対戦募集（上級）A
            </Link>
          </div>
          <div className="Rooms-tile">
            <div className="Rooms-tileUserNum">{this.getRoomNum("b")}</div>
            <Link to="/rooms/b" className="Rooms-tileLink">
              対戦募集（上級）B
            </Link>
          </div>
          <div className="Rooms-tile">
            <div className="Rooms-tileUserNum">{this.getRoomNum("c")}</div>
            <Link to="/rooms/c" className="Rooms-tileLink">
              対戦募集（初級）A
            </Link>
          </div>
          <div className="Rooms-tile">
            <div className="Rooms-tileUserNum">{this.getRoomNum("d")}</div>
            <Link to="/rooms/d" className="Rooms-tileLink">
              対戦募集（初級）B
            </Link>
          </div>
          <div className="Rooms-tile">
            <div className="Rooms-tileUserNum">{this.getRoomNum("e")}</div>
            <Link to="/rooms/e" className="Rooms-tileLink">
              身内部屋 A
            </Link>
          </div>
          <div className="Rooms-tile">
            <div className="Rooms-tileUserNum">{this.getRoomNum("f")}</div>
            <Link to="/rooms/f" className="Rooms-tileLink">
              身内部屋 B
            </Link>
          </div>
          <div className="Rooms-tile">
            <div className="Rooms-tileUserNum">{this.getRoomNum("g")}</div>
            <Link to="/rooms/g" className="Rooms-tileLink">
              身内部屋 C
            </Link>
          </div>
          <div className="Rooms-tile">
            <div className="Rooms-tileUserNum">{this.getRoomNum("h")}</div>
            <Link to="/rooms/h" className="Rooms-tileLink">
              身内部屋 D
            </Link>
          </div>
        </div>
        <div className="Rooms-contactInfo">
          <div className="Rooms-contactInfoContent">
            ぷよ関連ツール置き場:
            <a href="https://refpuyo.net">https://refpuyo.net</a>
          </div>
          <div className="Rooms-contactInfoContent">
            twitter:
            <a href="https://twitter.com/REF_puyo">
              https://twitter.com/REF_puyo
            </a>
          </div>
          <div className="Rooms-contactInfoContent">
            <br />
            更新履歴
            <ul>
              <li>2019/03/18 v0.3.0 オンライン人数表示追加</li>
              <li>
                2019/03/16 v0.2.1
                リプレイのぷよ譜出力機能追加・Twitterボタン追加
              </li>
              <li>2019/03/13 v0.2.0 リプレイ機能追加</li>
              <li>2019/03/10 v0.1.7 効果音 (SE) を追加</li>
              <li>2019/03/09 v0.1.6 入室時最新の状態にすぐ遷移するよう変更</li>
              <li>2019/02/27 v0.1.5 十字キー等で画面が移動しないよう固定</li>
              <li>
                2019/02/21 v0.1.4 連鎖残りフレーム表示・対戦相手の13段目隠し追加
              </li>
              <li>2019/02/20 v0.1.3 設置位置表示追加</li>
              <li>2019/02/19 v0.1.2 全消し表示・窒息点表示追加</li>
              <li>2019/02/17 v0.1.1 スキン追加・部屋名変更</li>
              <li>2019/02/16 v0.1.0 公開</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  getRoomNum(roomId: string): number {
    console.log(this.state.users);
    return this.state.users.filter(v => v.roomId === roomId).length;
  }
}

export default Rooms;
