import firebase, { FirebaseError } from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import {
  Puyotan,
  PuyoPos,
  PuyoField,
  PuyoKind,
  PuyoPair,
  PuyotanActionPass,
  PuyotanActionPut,
  IPuyotanAction
} from "../libs/Puyotan";
require("firebase/auth");

//インスタンスの初期化
firebase.initializeApp({
  apiKey: "AIzaSyDNckvdwFU9B-Xg3YPY-tgsrj09kg0MTxE",
  authDomain: "puyotan-be458.firebaseapp.com",
  databaseURL: "https://puyotan-be458.firebaseio.com",
  projectId: "puyotan-be458",
  storageBucket: "puyotan-be458.appspot.com",
  messagingSenderId: "1067679324903"
});

class DB {
  // debug用
  private dataCnt = 0;
  incDataCnt() {
    this.dataCnt++;
    console.log("dataCnt: " + this.dataCnt);
  }

  private firestore = firebase.firestore();
  private database = firebase.database();
  private puyoSkin: string;
  private user: firebase.User | null = null;
  private observeOnlineUserCallbacks: any[] = [];
  constructor() {
    firebase
      .auth()
      .signInAnonymously()
      .catch(error => {
        console.error("failed to authentication", error);
      });
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        this.user = user;
        if (user.displayName == null) {
          const num = Math.floor(Math.random() * 9999);
          user
            .updateProfile({
              displayName: `名無し${num}さん`,
              photoURL: null // photoURL: "https://example.com/profile.jpg"
            })
            .catch(error => {
              console.error("failed to initialize displayName");
            });
        }
        firebase
          .database()
          .ref(".info/connected")
          .on("value", function(snap) {
            if (snap && snap.val() === true) {
              let ref = firebase.database().ref("onlineUsers/" + user.uid);
              ref.onDisconnect().remove();
            }
          });
      } else {
        // User is signed out.
      }
    });
    this.puyoSkin = this.getPuyoSkin();
    firebase
      .database()
      .ref("onlineUsers")
      .on("value", snapshot => {
        if (!snapshot) return;
        const value = snapshot.val() || {};
        const users: any[] = [];
        Object.keys(value).forEach(key => {
          users.push(value[key]);
        });
        this.observeOnlineUserCallbacks.forEach(v => {
          v.callback(users);
        });
      });
  }

  getPuyoSkin(): string {
    return localStorage.getItem("puyoSkin") || "";
  }

  setPuyoSkin(skin: string) {
    localStorage.setItem("puyoSkin", skin);
  }

  getSeRange(): string {
    return localStorage.getItem("seRange") || "0";
  }

  setSeRange(range: string) {
    localStorage.setItem("seRange", range);
  }

  getFirebase() {
    return firebase;
  }

  getUser() {
    return this.user;
  }

  observeRoomDocument(
    roomId: string,
    callback: (doc: firebase.firestore.DocumentData) => void
  ) {
    return this.firestore
      .collection("rooms")
      .doc(roomId)
      .onSnapshot(
        // { includeMetadataChanges: true },
        docSnapshot => {
          const data = docSnapshot.data();
          this.incDataCnt();
          if (data) callback(data);
        }
      );
  }

  observeRoomUserCollection(
    roomId: string,
    callback: (docMap: Map<number, firebase.firestore.DocumentData>) => void
  ) {
    return this.firestore
      .collection("rooms")
      .doc(roomId)
      .collection("users")
      .onSnapshot(
        // { includeMetadataChanges: true },
        querySnapshot => {
          const docMap = new Map<number, firebase.firestore.DocumentData>();
          querySnapshot.forEach(doc => {
            docMap.set(Number(doc.id), doc.data());
            this.incDataCnt();
          });
          callback(docMap);
        }
      );
  }

  observeGameDocument(
    gameId: string,
    callback: (doc: firebase.firestore.DocumentData) => void
  ) {
    return this.firestore
      .collection("games")
      .doc(gameId)
      .onSnapshot(
        // { includeMetadataChanges: true },
        docSnapshot => {
          const data = docSnapshot.data();
          this.incDataCnt();
          if (data) callback(data);
        }
      );
  }

  fetchGameDocument(
    gameId: string,
    callback: (doc: firebase.firestore.DocumentData) => void
  ) {
    return this.firestore
      .collection("games")
      .doc(gameId)
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          if (data) callback(data);
        } else {
          // throw Error("failed to get game document");
        }
      });
  }

  observeGamePlayerDocument(
    gameId: string,
    playerId: number,
    callback: (doc: firebase.firestore.DocumentData | undefined) => void
  ) {
    return this.firestore
      .collection("games")
      .doc(gameId)
      .collection("players")
      .doc(String(playerId))
      .onSnapshot(docSnapshot => {
        console.log("docSnapshot", playerId);
        const data = docSnapshot.data();
        this.incDataCnt();
        callback(data);
      });
  }

  fetchGamePlayerDocument(
    gameId: string,
    playerId: number,
    callback: (doc: firebase.firestore.DocumentData) => void
  ) {
    return this.firestore
      .collection("games")
      .doc(gameId)
      .collection("players")
      .doc(String(playerId))
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          if (data) callback(data);
        }
      });
  }

  newGame(roomId: string, seed: string) {
    this.firestore
      .collection("games")
      .add({
        seed: seed,
        startAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(docRef => {
        this.firestore
          .collection("rooms")
          .doc(roomId)
          .update({
            gameId: docRef.id
          })
          .then(() => {})
          .catch(error => {
            console.error(error);
          });
      })
      .catch(error => {
        console.error("Error adding document: ", error);
      });
  }

  joinGame(roomId: string, id: number) {
    if (!this.user) return console.error("user is not authentication");
    this.firestore
      .collection("rooms")
      .doc(roomId)
      .collection("users")
      .doc(String(id))
      .set({
        uid: this.user.uid,
        name: this.user.displayName
      })
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  leaveGame(roomId: string, id: number) {
    this.firestore
      .collection("rooms")
      .doc(roomId)
      .collection("users")
      .doc(String(id))
      .delete()
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  sendGamePlayerActionMap(
    gameId: string,
    playerId: number,
    actionMap: Map<number, IPuyotanAction>
  ) {
    const map: firebase.firestore.DocumentData = {};
    actionMap.forEach((action, frame) => {
      map[frame] = Object.assign({}, action);
    });
    this.firestore
      .collection("games")
      .doc(gameId)
      .collection("players")
      .doc(String(playerId))
      .set({
        actionMap: map
      })
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  abortGame(roomId: string) {
    this.firestore
      .collection("rooms")
      .doc(roomId)
      .collection("users")
      .doc("0")
      .delete()
      .then(() => {
        this.firestore
          .collection("rooms")
          .doc(roomId)
          .collection("users")
          .doc("1")
          .delete()
          .then(() => {
            this.firestore
              .collection("rooms")
              .doc(roomId)
              .update({
                gameId: null
              });
          });
      });
  }

  sendChat(
    roomId: string,
    text: string,
    color: string,
    gameId: string | null = null
  ) {
    if (this.user == null) return;
    if (text.length > 32) return;
    if (text == "") return;
    const doc = {
      uid: this.user.uid,
      name: this.user.displayName,
      text: text,
      color: color,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    } as any;
    if (gameId) doc.gameId = gameId;
    this.firestore
      .collection("rooms")
      .doc(roomId)
      .collection("chats")
      .add(doc)
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  observeRoomChatCollection(
    roomId: string,
    callback: (docs: firebase.firestore.DocumentData[]) => void
  ) {
    return this.firestore
      .collection("rooms")
      .doc(roomId)
      .collection("chats")
      .orderBy("createdAt", "desc")
      .limit(8)
      .onSnapshot(querySnapshot => {
        const docs = new Array<firebase.firestore.DocumentData>();
        querySnapshot.docChanges().forEach(change => {
          if (change.type === "added") {
            docs.push(change.doc.data());
            this.incDataCnt();
          }
        });
        callback(docs);
      });
  }

  setProfile(name: string) {
    if (this.user == null) return;
    this.user
      .updateProfile({
        displayName: name,
        photoURL: null
        // photoURL: "https://example.com/jane-q-user/profile.jpg"
      })
      .then()
      .catch(error => {
        console.error("failed to set profile");
      });
  }

  moveRoom(roomId: string | null) {
    if (!this.user) return;
    let ref = firebase.database().ref("onlineUsers/" + this.user.uid);
    ref.set({
      name: this.user.displayName,
      roomId: roomId
    });
  }

  observeOnlineUsers(callback: (users: any[]) => void): () => void {
    let id = Math.floor(Math.random() * 1000000000);
    this.observeOnlineUserCallbacks.push({
      id: id,
      callback: callback
    });
    return () => {
      this.observeOnlineUserCallbacks = this.observeOnlineUserCallbacks.filter(
        v => v.id !== id
      );
    };
  }
}

export default new DB();
