import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import Rooms from "./components/Rooms";
import Room from "./components/Room";
import Replay from "./components/Replay";
import Config from "./components/Config";

ReactDOM.render(
  <BrowserRouter>
    <div>
      <AppHeader />
      <Switch>
        <Route exact path="/" component={Rooms} />
        <Route exact path="/rooms" component={Rooms} />
        <Route path="/rooms/:id" component={Room} />
        <Route path="/replays/:id" component={Replay} />
        <Route exact path="/config" component={Config} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <AppFooter />
    </div>
  </BrowserRouter>,
  document.getElementById("root")
);
