import DB from "../libs/DB";

class AudioManager {
  srcMap = new Map([
    ["gameStart", "/mp3/decision8.mp3"],
    ["gameSelect1", "/mp3/button01a.mp3"],
    ["gameSelect2", "/mp3/button06.mp3"]
  ]);
  audioCacheMap = new Map([
    ["gameStart", new Audio(this.srcMap.get("gameStart"))],
    ["gameSelect1", new Audio(this.srcMap.get("gameSelect1"))],
    ["gameSelect2", new Audio(this.srcMap.get("gameSelect2"))],
    ["gameWin", new Audio()],
    ["gameLose", new Audio()],
    ["gameDraw", new Audio()]
  ]);

  play(id: string) {
    const audio = this.audioCacheMap.get(id);
    if (audio == null) return;
    const volume = Number(DB.getSeRange()) / 100;
    if (volume == 0) return;
    audio.volume = volume;
    audio
      .play()
      .then(e => console.log("played", id))
      .catch(e => console.error(e));
    this.audioCacheMap.set(id, new Audio(this.srcMap.get(id)));
  }
}

export default new AudioManager();
