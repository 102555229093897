import React from "react";
import DB from "../libs/DB";
import AudioManager from "../libs/AudioManager";
import Base64Util from "../libs/Base64Util";
import {
  Puyotan,
  PuyoPos,
  PuyoField,
  PuyoKind,
  PuyoPair,
  PuyotanActionPut,
  IPuyotanAction,
  PuyotanActionType,
  PuyotanActionPass,
  PuyotanActionNone,
  PuyotanStatus,
  PuyotanPlayerHistory
} from "../libs/Puyotan";
import "./Game.css";

interface IProps {
  roomId: string;
}
interface IState {
  isLoaded: boolean;
  roomId: string;
  roomName: string;
  gameId: string | null;
  playerUidMap: Map<number, string>;
  playerNameMap: Map<number, string>;
  playerOperationMap: Map<number, IPlayerOperation>;
  playerActionHistoryMap: Map<number, IPlayerActionHistory>;
  seed: string;
  puyotan: IPuyotanState;
  uid: string | undefined;
}
interface IPlayerActionHistory {
  actionMap: Map<number, IPuyotanAction>;
}
interface IPlayerOperation {
  x: number;
  dir: number;
}
interface IPuyotanState {
  players: IPlayer[];
  frame: number;
  status: PuyotanStatus;
}
interface IPlayer {
  field: PuyoField;
  next: PuyoPair[];
  currentPair: PuyoPair;
  score: number;
  chain: number;
  ojamaNum: number;
  histories: PuyotanPlayerHistory[];
  isHoldAllClear: boolean;
  remainingFrame: number;
}

class Game extends React.Component<IProps, IState> {
  puyotan = new Puyotan(0);
  cancelRoomListener: Function | null = null;
  cancelRoomUserListener: Function | null = null;
  cancelGamePlayerActionListeners: Function[] = [];
  onAuthChangedListener: Function | null = null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoaded: false,
      roomId: props.roomId,
      roomName: "now loading...",
      gameId: null,
      playerUidMap: new Map(),
      playerNameMap: new Map(),
      playerOperationMap: new Map([
        [0, { x: 3, dir: 0 }],
        [1, { x: 3, dir: 0 }]
      ]),
      playerActionHistoryMap: new Map(),
      seed: "",
      puyotan: {
        frame: 0,
        players: [0, 1].map(id => ({
          field: new PuyoField(),
          next: [new PuyoPair(), new PuyoPair()],
          currentPair: new PuyoPair(),
          score: 0,
          ojamaNum: 0,
          chain: 0,
          histories: [],
          isHoldAllClear: false,
          remainingFrame: 0
        })),
        status: PuyotanStatus.WAIT
      },
      uid: undefined
    };

    (window as any).debug = () => {
      console.log(this.state);
    };
  }

  componentDidMount = () => {
    this.onAuthChangedListener = DB.getFirebase()
      .auth()
      .onAuthStateChanged(user => {
        if (user) {
          this.setState({
            uid: user.uid
          });
        } else {
          this.setState({
            uid: undefined
          });
        }
      });
    this.cancelRoomListener = DB.observeRoomDocument(this.state.roomId, doc => {
      console.log("RoomListener", doc);
      // gameId の変更があれば新規盤面へ移行する
      const gameId = doc.gameId;
      if (this.state.gameId !== gameId) {
        this.puyotan = new Puyotan(0);
        this.cancelReflectAction();
        this.setState({
          gameId: gameId,
          playerActionHistoryMap: new Map(),
          playerOperationMap: new Map([
            [0, { x: 3, dir: 0 }],
            [1, { x: 3, dir: 0 }]
          ]),
          puyotan: {
            frame: 0,
            players: [0, 1].map(id => ({
              field: new PuyoField(),
              next: [new PuyoPair(), new PuyoPair()],
              currentPair: new PuyoPair(),
              score: 0,
              chain: 0,
              ojamaNum: 0,
              histories: [],
              isHoldAllClear: false,
              remainingFrame: 0
            })),
            status: PuyotanStatus.WAIT
          }
        });
      }
      if (gameId) {
        DB.fetchGameDocument(gameId, doc => {
          const seed = doc.seed;
          let isFetchedFlags = [false, false];
          [0, 1].forEach(id => {
            if (this.cancelGamePlayerActionListeners[id])
              this.cancelGamePlayerActionListeners[id];
            this.cancelGamePlayerActionListeners[
              id
            ] = DB.observeGamePlayerDocument(gameId, id, doc => {
              if (doc != null && doc.actionMap != null) {
                const actionMap = new Map<number, IPuyotanAction>();
                Object.keys(doc.actionMap).forEach(key => {
                  actionMap.set(Number(key), doc.actionMap[key]);
                });
                const history = {
                  actionMap: actionMap
                };
                const historyMap = this.state.playerActionHistoryMap;
                historyMap.set(id, history);
                this.setState({
                  playerActionHistoryMap: historyMap
                });
              }
              const isLoadCompleted =
                !isFetchedFlags[id] &&
                isFetchedFlags.filter((_, i) => i !== id).every(v => v);
              isFetchedFlags[id] = true;
              if (isLoadCompleted) {
                // ロード完了時の処理
                console.log("ロード完了");
                this.puyotan = new Puyotan(Base64Util.base64stoNum(seed));
                this.puyotan.start();
                for (let _ = 0; _ < 1000; _++) {
                  // 1000は適当
                  const frame = this.puyotan.frame;
                  [0, 1].forEach(id => {
                    const history = this.state.playerActionHistoryMap.get(id);
                    if (history != null) {
                      const action = history.actionMap.get(frame);
                      if (action != null) {
                        this.puyotan.setAction(id, action);
                      }
                    }
                  });
                  if (!this.puyotan.canStepNextFrame()) break;
                  this.puyotan.stepNextFrame();
                }
                this.setState({
                  seed: seed,
                  puyotan: this.getPuyotanState()
                });
                if (this.puyotan.frame === 1) AudioManager.play("gameStart");
              } else {
                this.reflectAction();
              }
            });
          });
        });
      }
      this.setState({
        isLoaded: true,
        roomName: doc.name,
        gameId: doc.gameId,
        seed: doc.seed
      });
    });
    this.cancelRoomUserListener = DB.observeRoomUserCollection(
      this.state.roomId,
      docMap => {
        console.debug("RoomPlayerListener", docMap);
        const uidMap = new Map<number, string>();
        const nameMap = new Map<number, string>();
        docMap.forEach((v, k) => {
          uidMap.set(k, v.uid);
          nameMap.set(k, v.name);
        });
        this.setState({
          playerUidMap: uidMap,
          playerNameMap: nameMap
        });
      }
    );

    // keyboard 操作用
    window.addEventListener("keydown", this.keyDownHandler);
    // スマホ拡大防止（ダブルタップ）
    window.addEventListener("touchend", this.touchEndHandler, false);
  };

  touchEndHandler = (event: any) => {
    if (event.target.nodeName === "A") return;
    if (event.target.nodeName === "INPUT") return;
    event.preventDefault();
  };

  keyDownHandler = (e: KeyboardEvent) => {
    const activeElm = document.activeElement;
    if (activeElm != null && activeElm.id == "Room-chatInput") {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    switch (e.keyCode) {
      case 37: // ←キー
        this.opsMoveLeft();
        return;
      case 39: // →キー
        this.opsMoveRight();
        return;
      case 38: // ↑キー
        return;
      case 40: // ↓キー
        this.opsPutTumo();
        return;
      case 49: // 1
        return;
      case 50: // 2
        return;
      case 51: // 3
        return;
      case 52: // 4
        return;
      case 53: // 5
        return;
      case 54: // 6
        return;
      case 55: // 7
        return;
      case 56: // 8
        return;
      case 88: // x
        this.opsTurnRight();
        return;
      case 90: // z
        this.opsTurnLeft();
        return;
      case 67: // c
        return;
      case 68: // d
        return;
      case 69: // e
        return;
      case 78: // n
        return;
      case 80: // p
        // this.pass();
        return;
      case 82: // r
        // this.sendInit(Number(this.state.seed) + 1);
        return;
      case 83: // s
        // this.sendInit();
        return;
      case 84: // t
        return;
      default:
        return;
    }
  };

  componentWillUnmount() {
    if (this.cancelRoomListener) this.cancelRoomListener();
    if (this.cancelRoomUserListener) this.cancelRoomUserListener();
    this.cancelGamePlayerActionListeners.forEach(cancelListener => {
      cancelListener();
    });
    if (this.onAuthChangedListener) this.onAuthChangedListener();
    this.cancelReflectAction();
    window.removeEventListener("keydown", this.keyDownHandler);
    window.removeEventListener("touchend", this.touchEndHandler);
  }

  render(): JSX.Element {
    const isActive1P = this.state.playerUidMap.get(0) == this.state.uid;
    const isActive2P = this.state.playerUidMap.get(1) == this.state.uid;
    const canStart =
      this.state.playerUidMap.get(0) &&
      this.state.playerUidMap.get(1) &&
      this.state.puyotan.status !== PuyotanStatus.PLAY;
    const canStart1P = canStart && isActive1P;
    const canStart2P = canStart && isActive2P;
    const visibleButtons =
      this.state.isLoaded &&
      this.state.uid &&
      (!this.state.gameId ||
        this.state.puyotan.status === PuyotanStatus.FINISH);
    return (
      <div className="Game">
        <div className="Game-roomName">[ {this.state.roomName} ]</div>
        {this.playerInfoToJSX(0)}
        {this.playerInfoToJSX(1)}
        {this.frameToJSX()}
        {this.ojamaToJSX(0)}
        {this.ojamaToJSX(1)}
        {this.fieldToJSX(0)}
        {this.fieldToJSX(1)}
        {this.nextToJSX(0, 0)}
        {this.nextToJSX(0, 1)}
        {this.nextToJSX(1, 0)}
        {this.nextToJSX(1, 1)}
        {this.scoreToJSX(0)}
        {this.scoreToJSX(1)}
        {this.chainToJSX(0)}
        {this.chainToJSX(1)}
        {this.fieldCeilingToJSX(0)}
        {this.fieldCeilingToJSX(1)}
        {this.remainingFrameToJSX(0)}
        {this.remainingFrameToJSX(1)}
        {!visibleButtons
          ? null
          : [
              this.buttonToJSX(
                0,
                "[1P] 着席",
                "Game-buttonJoin1P",
                isActive1P,
                () => {
                  this.opsJoin(0);
                }
              ),
              this.buttonToJSX(
                1,
                "[1P] 離席",
                "Game-buttonLeave1P",
                !isActive1P,
                () => this.opsLeave(0)
              ),
              this.buttonToJSX(
                2,
                "[2P] 着席",
                "Game-buttonJoin2P",
                isActive2P,
                () => this.opsJoin(1)
              ),
              this.buttonToJSX(
                3,
                "[2P] 離席",
                "Game-buttonLeave2P",
                !isActive2P,
                () => this.opsLeave(1)
              ),
              this.buttonToJSX(
                4,
                "ゲーム開始",
                "Game-buttonStart1P",
                !canStart1P,
                this.opsStart
              ),
              this.buttonToJSX(
                5,
                "ゲーム開始",
                "Game-buttonStart2P",
                !canStart2P,
                this.opsStart
              )
            ]}
        {this.buttonToJSX(
          6,
          "L",
          "Game-buttonTurnLeft",
          false,
          this.opsTurnLeft
        )}
        {this.buttonToJSX(
          7,
          "R",
          "Game-buttonTurnRight",
          false,
          this.opsTurnRight
        )}
        {this.buttonToJSX(
          8,
          "⇦",
          "Game-buttonMoveLeft",
          false,
          this.opsMoveLeft
        )}
        {this.buttonToJSX(
          9,
          "⇨",
          "Game-buttonMoveRight",
          false,
          this.opsMoveRight
        )}
        {this.buttonToJSX(
          10,
          "⇓",
          "Game-buttonPutTumo",
          false,
          this.opsPutTumo
        )}
        {this.buttonToJSX(
          11,
          "強制試合終了",
          "Game-buttonAbort",
          false,
          this.opsAbort
        )}
      </div>
    );
  }

  playerInfoToJSX(id: number): JSX.Element {
    const name = this.state.playerNameMap.get(id) || "";
    const history = this.state.puyotan.players[id].histories[
      this.state.puyotan.frame
    ];
    let isSelected = history != null;
    return (
      <div className={`Game-playerInfo Game-playerInfo${id + 1}P`}>
        {/* <div className="Game-playerInfoImage" /> */}
        <div
          className={
            `Game-playerInfoIsSelect ` +
            (isSelected ? "" : "Game-playerInfoNotSelect")
          }
        >
          {isSelected ? "選択済" : "選択中"}
        </div>
        <div className="Game-playerInfoName">{name}</div>
      </div>
    );
  }

  fieldToJSX(id: number): JSX.Element {
    let player = this.state.puyotan.players[id];
    let field = player.field;
    let puyoElements = new Array<JSX.Element>();
    for (let y = 1; y <= 13; y++) {
      for (let x = 1; x <= 6; x++) {
        const pos = new PuyoPos(x, y);
        const kind = field.get(pos);
        const puyoStyle = this.getFieldPuyoStyle(pos);
        puyoElements.push(
          <div
            key={`${x}_${y}`}
            className={`Game-puyo ${this.puyoKindToClassName(kind)}`}
            style={puyoStyle}
          />
        );
      }
    }
    const selfUid = this.state.uid;
    let isActive = selfUid && this.state.playerUidMap.get(id) == this.state.uid;
    const className = `Game-field Game-field${id + 1}P ${
      isActive ? "Game-fieldActive" : ""
    }`;
    return (
      <div className={className}>
        <div className="Game-peke" />
        {player.isHoldAllClear ? <div className="Game-isHoldAllClear" /> : null}
        {puyoElements}
        {this.controlPairToJSX(id)}
        {this.ghostPairToJSX(id)}
      </div>
    );
  }

  getFieldPuyoStyle(pos: PuyoPos) {
    return {
      left: (pos.x - 1) * 32,
      top: (13 - pos.y) * 32
    };
  }

  controlPairToJSX(id: number): JSX.Element | null {
    //       active  nonactive
    // null  操作位置 初期位置
    // ojama 非表示   非表示
    // put   設置位置 設置位置（誰かが選択中は初期位置）
    // chain 非表示   非表示
    // fall  非表示   非表示
    // PLAY中以外 → 全て非表示
    const pair = this.state.puyotan.players[id].currentPair;
    let operation = this.state.playerOperationMap.get(id);
    if (operation == null) return null;
    const isActive = this.state.playerUidMap.get(id) === this.state.uid;
    const currentHistory = this.state.puyotan.players[id].histories[
      this.state.puyotan.frame
    ];
    if (this.state.puyotan.status !== PuyotanStatus.PLAY) return null;
    if (currentHistory) {
      const currentAction = currentHistory.action as any;
      if (currentAction.type == PuyotanActionType.OJAMA) return null;
      if (currentAction.type == PuyotanActionType.CHAIN) return null;
      if (currentAction.type == PuyotanActionType.CHAIN_FALL) return null;
      if (currentAction.type == PuyotanActionType.PUT) {
        if (
          !isActive &&
          this.state.puyotan.players.some(p => !p.histories[this.puyotan.frame])
        ) {
          operation = {
            x: 3,
            dir: 0
          };
        } else {
          operation = {
            x: currentAction.x,
            dir: currentAction.dir
          };
        }
      }
    } else {
      if (!isActive) {
        operation = {
          x: 3,
          dir: 0
        };
      }
    }
    let axisPos = new PuyoPos(operation.x, 15);
    let subPos = this.getSubPos(axisPos, operation.dir);
    return (
      <div className="Game-puyo">
        <div
          className={`${this.puyoKindToClassName(pair.sub)}`}
          style={this.getFieldPuyoStyle(subPos)}
        />
        <div
          className={`${this.puyoKindToClassName(pair.axis)}`}
          style={this.getFieldPuyoStyle(axisPos)}
        />
      </div>
    );
  }

  ghostPairToJSX(id: number) {
    const pair = this.state.puyotan.players[id].currentPair;
    let operation = this.state.playerOperationMap.get(id);
    if (operation == null) return null;
    const isActive = this.state.playerUidMap.get(id) === this.state.uid;
    const currentHistory = this.state.puyotan.players[id].histories[
      this.state.puyotan.frame
    ];
    if (this.state.puyotan.status !== PuyotanStatus.PLAY) return null;
    if (!isActive) return null;
    if (currentHistory) {
      const currentAction = currentHistory.action as any;
      if (currentAction.type != PuyotanActionType.PUT) return null;
      operation = {
        x: currentAction.x,
        dir: currentAction.dir
      };
    }
    let axisPos = new PuyoPos(operation.x, 15);
    let subPos = this.getSubPos(axisPos, operation.dir);
    const field = this.state.puyotan.players[id].field;
    const heigherY = Math.max(
      field.getHeight(axisPos.x),
      field.getHeight(subPos.x)
    );
    const extY = operation.dir == 2 ? 1 : 0;
    const ghostAxisPos = new PuyoPos(axisPos.x, heigherY + 1 + extY);
    const ghostSubPos = this.getSubPos(ghostAxisPos, operation.dir);
    return (
      <div className="Game-puyoGhost">
        <div
          className={`${this.puyoKindToClassName(pair.sub)}`}
          style={this.getFieldPuyoStyle(ghostSubPos)}
        />
        <div
          className={`${this.puyoKindToClassName(pair.axis)}`}
          style={this.getFieldPuyoStyle(ghostAxisPos)}
        />
      </div>
    );
  }

  getSubPos(axisPos: PuyoPos, dir: number) {
    switch (dir) {
      case 0:
        return new PuyoPos(axisPos.x, axisPos.y + 1);
      case 1:
        return new PuyoPos(axisPos.x + 1, axisPos.y);
      case 2:
        return new PuyoPos(axisPos.x, axisPos.y - 1);
      case 3:
        return new PuyoPos(axisPos.x - 1, axisPos.y);
      default:
        throw Error(`unsupported dir ${dir}`);
    }
  }

  nextToJSX(id: number, nextId: number): JSX.Element {
    const pair = this.state.puyotan.players[id].next[nextId];
    return (
      <div className={`Game-next Game-next${nextId + 1}${id + 1}P`}>
        <div className={`${this.puyoKindToClassName(pair.sub)}`} />
        <div
          className={`${this.puyoKindToClassName(pair.axis)} Game-nextAxis`}
        />
      </div>
    );
  }

  scoreToJSX(id: number): JSX.Element {
    const score = this.state.puyotan.players[id].score;
    return (
      <div className={`Game-score Game-score${id + 1}P`}>
        <div className="Game-scoreLeft">Score</div>
        <div className="Game-scoreRight">{score}</div>
      </div>
    );
  }

  chainToJSX(id: number): JSX.Element {
    const chain = this.state.puyotan.players[id].chain;
    return (
      <div className={`Game-chain Game-chain${id + 1}P`}>{chain} 連鎖</div>
    );
  }

  fieldCeilingToJSX(id: number): JSX.Element | null {
    const isActive = this.state.playerUidMap.get(id) === this.state.uid;
    if (isActive) return null;
    return <div className={`Game-fieldCeiling Game-fieldCeiling${id + 1}P`} />;
  }

  remainingFrameToJSX(id: number): JSX.Element | null {
    const remainingFrame = this.state.puyotan.players[id].remainingFrame;
    if (remainingFrame <= 0) return null;
    return (
      <div className={`Game-remainingFrame Game-remainingFrame${id + 1}P`}>
        残り: {remainingFrame}
      </div>
    );
  }

  ojamaToJSX(id: number): JSX.Element {
    let ojamaNum = this.state.puyotan.players[id].ojamaNum;
    const ojama7Num = Math.floor(ojamaNum / 1440);
    ojamaNum -= ojama7Num * 1440;
    const ojama6Num = Math.floor(ojamaNum / 720);
    ojamaNum -= ojama6Num * 720;
    const ojama5Num = Math.floor(ojamaNum / 360);
    ojamaNum -= ojama5Num * 360;
    const ojama4Num = Math.floor(ojamaNum / 180);
    ojamaNum -= ojama4Num * 180;
    const ojama3Num = Math.floor(ojamaNum / 30);
    ojamaNum -= ojama3Num * 30;
    const ojama2Num = Math.floor(ojamaNum / 6);
    ojamaNum -= ojama2Num * 6;
    const ojama1Num = ojamaNum;
    let ojamaList = [];
    for (let i = 0; i < ojama7Num; i++) ojamaList.push(7);
    for (let i = 0; i < ojama6Num; i++) ojamaList.push(6);
    for (let i = 0; i < ojama5Num; i++) ojamaList.push(5);
    for (let i = 0; i < ojama4Num; i++) ojamaList.push(4);
    for (let i = 0; i < ojama3Num; i++) ojamaList.push(3);
    for (let i = 0; i < ojama2Num; i++) ojamaList.push(2);
    for (let i = 0; i < ojama1Num; i++) ojamaList.push(1);
    let jsxList = [];
    for (let i = 0; i < Math.min(ojamaList.length, 6); i++) {
      jsxList.push(
        <div
          key={`${i}`}
          className={`Game-ojama Game-ojama${ojamaList[i]}`}
          style={{
            left: 32 * i
          }}
        />
      );
    }
    return (
      <div className={`Game-ojamaArea Game-ojamaArea${id + 1}P`}>{jsxList}</div>
    );
  }

  buttonToJSX(
    key: number,
    value: string,
    className: string,
    disabled: boolean,
    onClick: () => void
  ): JSX.Element {
    let isActive = false;
    const onStart = (e: any) => {
      if (disabled) return;
      const tapEventType =
        window.ontouchstart === null ? "touchstart" : "mousedown";
      if (e.type === tapEventType) {
        onClick();
        isActive = true;
        // console.log("start");
      }
    };
    const onEnd = (e: any) => {
      isActive = false;
      // console.log("end");
    };
    // const onRef = (elm: any) => {
    //   if (elm == null) return;
    //   if (isActive) {
    //     if (elm.classList != null) {
    //       elm.classList.add("Game-buttonActive");
    //     }
    //   } else {
    //     if (elm.classList != null) {
    //       elm.classList.remove("Game-buttonActive");
    //     }
    //   }
    // };
    return (
      <div
        key={key}
        // ref={onRef}
        onTouchStart={onStart}
        onMouseDown={onStart}
        onTouchEnd={onEnd}
        onMouseUp={onEnd}
        onMouseOut={onEnd}
        className={`Game-button ${className} ${
          disabled ? `Game-buttonDisabled` : ""
        }`}
      >
        {value}
      </div>
    );
  }

  frameToJSX(): JSX.Element {
    const frame = this.state.puyotan.frame;
    return (
      <div className="Game-frameArea">
        <div className="Game-frameText">フレーム</div>
        <div className="Game-frameNumber">{frame}</div>
      </div>
    );
  }

  puyoKindToClassName(kind: PuyoKind) {
    const prefix = DB.getPuyoSkin() == "B" ? "B" : "";
    switch (kind) {
      case PuyoKind.RED:
        return "Game-puyo Game-puyoRed" + prefix;
      case PuyoKind.BLUE:
        return "Game-puyo Game-puyoBlue" + prefix;
      case PuyoKind.GREEN:
        return "Game-puyo Game-puyoGreen" + prefix;
      case PuyoKind.YELLOW:
        return "Game-puyo Game-puyoYellow" + prefix;
      case PuyoKind.OJAMA:
        return "Game-puyo Game-puyoOjama";
      default:
        return "Game-puyo Game-puyoBlank";
    }
  }

  opsStart = () => {
    const seed = Math.floor(Math.random() * 999999);
    const name1 = this.state.playerNameMap.get(0);
    const name2 = this.state.playerNameMap.get(1);
    DB.newGame(this.state.roomId, Base64Util.numToBase64s(seed));
    DB.sendChat(
      this.state.roomId,
      `ゲーム開始 ${name1} vs ${name2}`,
      "#0000ff"
    );
  };

  opsJoin = (id: number) => {
    DB.joinGame(this.state.roomId, id);
  };

  opsLeave = (id: number) => {
    DB.leaveGame(this.state.roomId, id);
  };

  opsMoveLeft = () => {
    const opsMap = this.state.playerOperationMap;
    [0, 1].forEach(id => {
      const ope = this.state.playerOperationMap.get(id);
      if (ope == null) return;
      if (ope.x > 2 || (ope.x === 2 && ope.dir !== 3)) {
        ope.x--;
        opsMap.set(id, ope);
      }
    });
    this.setState({
      playerOperationMap: opsMap
    });
  };

  opsMoveRight = () => {
    const opsMap = this.state.playerOperationMap;
    [0, 1].forEach(id => {
      const ope = this.state.playerOperationMap.get(id);
      if (ope == null) return;
      if (ope.x < 5 || (ope.x === 5 && ope.dir !== 1)) {
        ope.x++;
        opsMap.set(id, ope);
      }
    });
    this.setState({
      playerOperationMap: opsMap
    });
  };

  opsTurnLeft = () => {
    const opsMap = this.state.playerOperationMap;
    [0, 1].forEach(id => {
      const ope = this.state.playerOperationMap.get(id);
      if (ope == null) return;
      let adjust = 0;
      if (ope.x === 1 && ope.dir === 0) adjust = 1;
      if (ope.x === 6 && ope.dir === 2) adjust = -1;
      opsMap.set(id, {
        x: ope.x + adjust,
        dir: (ope.dir + 3) % 4
      });
    });
    this.setState({
      playerOperationMap: opsMap
    });
  };

  opsTurnRight = () => {
    const opsMap = this.state.playerOperationMap;
    [0, 1].forEach(id => {
      const ope = this.state.playerOperationMap.get(id);
      if (ope == null) return;
      let adjust = 0;
      if (ope.x === 1 && ope.dir === 2) adjust = 1;
      if (ope.x === 6 && ope.dir === 0) adjust = -1;
      opsMap.set(id, {
        x: ope.x + adjust,
        dir: (ope.dir + 1) % 4
      });
    });
    this.setState({
      playerOperationMap: opsMap
    });
  };

  opsPutTumo = () => {
    const gameId = this.state.gameId;
    const uid = this.state.uid;
    if (gameId == null) return;
    if (uid == null) return;
    [0, 1].forEach(id => {
      if (uid === this.state.playerUidMap.get(id)) {
        const ops = this.state.playerOperationMap.get(id);
        if (ops == null) return;
        let actionMap = new Map<number, IPuyotanAction>();
        const history = this.state.playerActionHistoryMap.get(id);
        if (history) actionMap = history.actionMap;
        actionMap.set(
          this.state.puyotan.frame,
          new PuyotanActionPut(ops.x, ops.dir)
        );
        DB.sendGamePlayerActionMap(gameId, id, actionMap);
      }
    });
  };

  opsAbort = () => {
    if (confirm("試合を強制終了します。本当によろしいですか？")) {
      DB.abortGame(this.state.roomId);
      DB.sendChat(
        this.state.roomId,
        "ゲームを強制終了しました",
        "#ff0000",
        this.state.gameId
      );
    }
  };

  isActiveReflectAction = false;
  reflectActionTimeoutId: NodeJS.Timeout | null = null;

  cancelReflectAction = () => {
    if (this.isActiveReflectAction && this.reflectActionTimeoutId != null) {
      clearTimeout(this.reflectActionTimeoutId);
      this.reflectActionTimeoutId = null;
      this.isActiveReflectAction = false;
    }
  };

  reflectAction = () => {
    if (this.isActiveReflectAction) return;
    this.isActiveReflectAction = true;
    // reflect action
    [0, 1].forEach(id => {
      const history = this.state.playerActionHistoryMap.get(id);
      if (!history) return;
      console.log("history", history);
      const action = history.actionMap.get(this.puyotan.frame);
      if (action == null) return;
      if (this.puyotan.setAction(id, action)) {
        if (id === 0) AudioManager.play("gameSelect1");
        if (id === 1) AudioManager.play("gameSelect2");
      }
    });
    if (this.puyotan.canStepNextFrame()) {
      this.puyotan.stepNextFrame();
      const map = this.state.playerOperationMap;
      this.puyotan.players.forEach((p, id) => {
        if (!p.histories[this.puyotan.frame])
          map.set(id, {
            x: 3,
            dir: 0
          });
      });
      this.setState({
        playerOperationMap: map,
        puyotan: this.getPuyotanState()
      });
    } else {
      this.setState({
        puyotan: this.getPuyotanState()
      });
    }
    //
    if (
      this.puyotan.canStepNextFrame() ||
      [0, 1].some(id => {
        const history = this.state.playerActionHistoryMap.get(id);
        if (history == null) return false;
        return history.actionMap.get(this.puyotan.frame) != null;
      })
    ) {
      this.reflectActionTimeoutId = setTimeout(() => {
        this.isActiveReflectAction = false;
        this.reflectAction();
      }, 500);
    } else {
      this.isActiveReflectAction = false;
      if (this.puyotan.status === PuyotanStatus.FINISH) {
        console.log(
          "finish!!",
          this.puyotan.winnerId,
          this.state.playerUidMap,
          this.state.uid
        );
        if (this.state.playerUidMap.get(0) === this.state.uid) {
          DB.sendChat(
            this.state.roomId,
            "ゲームが終了しました",
            "#0000ff",
            this.state.gameId
          );
        }
      }
    }
  };

  getPuyotanState = () => {
    return {
      frame: this.puyotan.frame,
      players: this.puyotan.players.map((p, id) => ({
        field: p.field,
        next: [
          this.puyotan.next.getPuyoPair(p.nextPos + 1),
          this.puyotan.next.getPuyoPair(p.nextPos + 2)
        ],
        currentPair: this.puyotan.next.getPuyoPair(p.nextPos),
        score: p.score,
        chain: p.chain,
        ojamaNum: p.activeOjama + p.nonActiveOjama,
        histories: p.histories,
        isHoldAllClear: p.isHoldAllClear,
        remainingFrame: this.getRemainingFrame(this.puyotan, id)
      })),
      status: this.puyotan.status
    };
  };

  /** 連鎖完了まであと何フレームあるか計算して返す */
  getRemainingFrame(puyotan: Puyotan, id: number) {
    const pt = new Puyotan(puyotan.seed);
    pt.start();
    for (let i = 1; i < puyotan.frame; i++) {
      const history0 = puyotan.players[0].histories[i];
      const history1 = puyotan.players[1].histories[i];
      if (history0 != null) pt.setAction(0, history0.action);
      if (history1 != null) pt.setAction(1, history1.action);
      pt.stepNextFrame();
    }
    for (let i = 0; i < 1000; i++) {
      // 1000は適当
      const history = pt.players[id].histories[puyotan.frame + i];
      if (history == null) return i;
      if (
        history.action.type !== PuyotanActionType.CHAIN &&
        history.action.type !== PuyotanActionType.CHAIN_FALL
      )
        return i;
      for (let j = 0; j < 2; j++) {
        if (j !== id) pt.setAction(j, new PuyotanActionNone());
      }
      pt.stepNextFrame();
    }
    throw new Error("unreachable code");
  }
}

export default Game;
