import React from "react";
import "./AppFooter.css";

class AppFooter extends React.Component {
  componentDidMount() {}

  render(): JSX.Element {
    return (
      <div className="AppFooter">
        <div className="AppFooter-twitterButton">
          <div
            onClick={e => {
              window.open(
                `https://twitter.com/share?text=${encodeURI(
                  document.title
                )}&url=${location.href}&hashtags=ぷよたん`,
                "tweetwindow",
                "width=650, height=470, personalbar=0, toolbar=0, scrollbars=1, sizable=1"
              );
              return false;
            }}
            className="twitter-share-button"
          >
            Tweet
          </div>
        </div>
      </div>
    );
  }
}

export default AppFooter;
